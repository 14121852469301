<template>
  <div class="phone-selector" @click.stop>
    <div class="phone-input">
      <span class="prefix" @click="toggleDropdown">{{ selectedCountry.phone_prefix || 'Select' }}</span>
      <input
        type="text"
        v-model="phoneNumber"
        placeholder="Enter phone number"
        @focus="handlePhoneInputFocus"
        @blur="hideDropdown"
        @input="emitPhoneNumber"
      />
    </div>
    <div class="country-selector" v-if="showDropdown">
      <div class="dropdown">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search country"
          class="country-search"
          ref="countrySearchInput"
        />
        <ul>
          <li
            v-for="country in filteredCountries"
            :key="country.country_id"
            @mousedown.prevent="selectCountry(country)"
          >
            {{ country.name }} ({{ country.phone_prefix }})
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import countries from '@/assets/json/countries-en.json';

export default {
  data() {
    return {
      countries,
      searchQuery: '',
      selectedCountry: { phone_prefix: '' },
      phoneNumber: '',
      showDropdown: false,
    };
  },
  computed: {
    filteredCountries() {
      return this.countries.filter(country =>
        country.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    fullPhoneNumber() {
      return `${this.selectedCountry.phone_prefix}${this.phoneNumber}`;
    },
  },
  methods: {
    selectCountry(country) {
      this.selectedCountry = country;
      this.showDropdown = false;
      this.emitPhoneNumber();
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        this.$nextTick(() => {
          this.$refs.countrySearchInput.focus();
        });
      }
    },
    handlePhoneInputFocus() {
      if (!this.selectedCountry.phone_prefix) {
        this.showDropdown = true;
      }
    },
    hideDropdown() {
      setTimeout(() => {
        this.showDropdown = false;
      }, 200);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    emitPhoneNumber() {
      this.$emit('update:phoneNumber', this.fullPhoneNumber);
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.phone-selector {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.85em;
  margin-bottom: 1rem;
}

.phone-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 1.25em;
  cursor: text;
  background-color: #fff;
  width: 100%;
}

.phone-input .prefix {
  margin-left: 8px;
  color: #555;
  cursor: pointer;
}

.phone-input input {
  border: none;
  outline: none;
  flex: 1;
  background-color: transparent;
  padding: 15px 0 0 15px;
}

.country-selector {
  position: relative;
}

.country-search {
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  margin-top: 4px;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
  color: #333;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}
</style>
